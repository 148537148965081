import Head, { defaultShareImage } from 'components/Head'
import Home from 'components/Home'
import SiteLayout from 'components/SiteLayout'
import { useDatePagination, useFeedPageView, useLoggedInUser, useStore } from 'hooks'
import { StoriesStore } from 'stores/stories'
import { getStaticWrapper, getSupplySidePlatformKeyValues, useClientInfo } from 'utils'
import { formatHomepageStructuredDataMarkup, brandDescription } from 'lib/seo'

const HomePage = ({ items, featuredItems, trendingItems }) => {
  const client = useClientInfo()
  const user = useLoggedInUser()
  const url = `${process.env.SITE_URL}`
  const storiesStore = useStore(StoriesStore, { items, featuredItems, trendingItems })
  const pagination = useDatePagination(storiesStore.items, (query) =>
    storiesStore.findNext({ ...query, exclude_category: '420' })
  )

  useFeedPageView(pagination)

  const adSettings = {
    slot: 'home',
    keyValues: {},
    // {} no story for home page
    ssp: getSupplySidePlatformKeyValues(client, user, {})
  }

  return (
    <>
      <SiteLayout adSettings={adSettings}>
        <Head isHomepage={true} title='Barstool Sports' description={brandDescription} />
        <>{formatHomepageStructuredDataMarkup({ description: brandDescription, defaultShareImage, url })}</>
        <Home {...pagination} adSettings={adSettings} />
      </SiteLayout>
    </>
  )
}

export const getStaticProps = getStaticWrapper(async () => {
  const [items, featuredItems, trendingItems] = await Promise.all([
    StoriesStore().find({ exclude_category: '420' }),
    StoriesStore().find({ featured: true, limit: 3, exclude_category: '420' }),
    StoriesStore().findTrending({ exclude_featured: true, limit: 5 })
  ])
  return {
    items,
    featuredItems,
    trendingItems
  }
})

export default HomePage
